import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SearchPage from './components/SearchPage';
import AgeVerification from './components/AgeVerification';
import { StickyBanner } from "exoclick-react"
import StickyBannerComponent from './components/StickyBannerComponent';

function App() {
  const [showAgeVerification, setShowAgeVerification] = useState(true);

  const handleVerification = (isAdult) => {
    if (isAdult) {
      setShowAgeVerification(false);
      // Optionally save to localStorage to persist the verification
      localStorage.setItem('ageVerified', 'true');
    } else {
      window.location.href = 'https://neopets.com';
    }
  };

  useEffect(() => {
    // Check if user has already verified their age
    const verified = localStorage.getItem('ageVerified');
    if (verified === 'true') {
      setShowAgeVerification(false);
    }
  }, []);

  return (
    <>
      {showAgeVerification && <AgeVerification onVerify={handleVerification} />}
      {/* {!showAgeVerification && <PopMagicAdScript/>} */}
      <Router>
        <div className="min-h-screen mx-auto px-4 py-12 container">
          {/* <AdComponent className="eas6a97888e2" data-zoneid="5487330" /> */}
          <h1 className="text-4xl font-bold mb-12 text-center">
            <a href='/'>
            <img src="img/logo.png" alt="Pookiedex" className="w-2/3 mx-auto" />
              {/* <span className="text-[#00aff0] relative after:content-[''] 
              after:absolute after:w-full after:h-1 after:bg-[#00aff0]/20 
              after:-bottom-2 after:left-0 after:rounded-full hover:after:bg-[#00aff0]/40 
              transition-all duration-300">
                Pookiedex
              </span> */}
            </a>
          </h1>
          <Routes>
            <Route path="/" element={<SearchPage />} />
          </Routes>
        </div>
        {/* <AdComponent className="eas6a97888e2" data-zoneid="5487350" /> */}
        <StickyBannerComponent/>
      </Router>
    </>
  );
}

export default App;