import { useEffect, useState } from 'react';

// Debounce function
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

function SearchBar({ onSearch, initialQuery = '' }) {
  const [query, setQuery] = useState(initialQuery);
  const debouncedQuery = useDebounce(query, 300); // Adjust delay as needed


    // Call onSearch once on component mount
    useEffect(() => {
      onSearch(initialQuery.trim());
    }, []); // Empty dependency array ensures this runs only once

  useEffect(() => {
      onSearch(debouncedQuery);
  }, [debouncedQuery]);

  return (
    <form className="flex gap-4 max-w-2xl mx-auto">
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Search for profiles..."
        className="flex-1 px-6 py-3 border border-zinc-700 rounded-lg bg-zinc-900 
        focus:outline-none focus:ring-2 focus:ring-[#00aff0] focus:border-transparent
        transition-all duration-200"
      />
    </form>
  );
}

export default SearchBar; 