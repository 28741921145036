import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./index.css";
import RawScript from './components/RawAdScript';
import RawRemoteScript from './components/RawRemoteScript';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RawScript/>
    <RawRemoteScript/>
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
    <div className='bg-[#232531] text-gray-200'>
      <App />
    </div>
  </React.StrictMode>
); 