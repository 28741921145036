import { useState } from 'react';

function ProfileCard({ profile, isExpanded, onExpand }) {
  const pics = process.env.REACT_APP_SHOW_PICS === 'true';

  const { name, profile_picture, description, stats, url, price } = profile._source;
  const likes = stats.Likes
  const photos = stats.Photos
  const videos = stats.Videos
  var source = ''
  var logo = ''
  var pricetext = price

  if (url && url.includes("onlyfans")) {
    source = 'OnlyFans'
    logo = '/img/logo_onlyfans.png'
  }
  if (url && url.includes("fansly")) {
    source = 'Fansly'
    logo = '/img/logo_fansly.png'
  }
  if (price.includes('not found')) {
    pricetext = 'For free'
  }

  const handleExpandClick = (e) => {
    e.stopPropagation();
    onExpand();
  };

  return (
    <div 
      className="bg-[#333645] border-zinc-700 border-2 rounded-3xl p-6 shadow-lg
        hover:bg-zinc-800/50 hover:border-[#00aff0] hover:shadow-[#00aff0]/10
        group transition-all duration-300 relative h-full flex flex-col
        backdrop-blur-sm cursor-pointer"
      onClick={onExpand}
    >
      <div className="group-hover:scale-[1.01] transition-transform duration-300">
        <div className="grid grid-cols-3">
          <div className="flex flex-col col-span-1 items-center justify-center mr-4 pb-8">
            {pics &&
              <img
                src={"data:image/jpeg;base64," + profile_picture}
                alt={name}
                className="w-32 h-32 rounded-full object-cover ring-4 ring-white"
              />}
          </div>
          <div className="flex flex-col gap-3 mb-5 col-span-2">
            <div className="flex flex-col">
              <h3 className="font-bold text-2xl text-white">{name}</h3>
              <span className="text-gray-400">@{name.toLowerCase().replace(/\s/g, '')}</span>
            </div>

          <div className="flex gap-6 text-sm text-gray-400 justify-start">
            <span className="flex items-center gap-2">
              <span className="material-icons" style={{ color: '#991B1B' }}>favorite</span>
              <span className="text-white">{likes}</span>
            </span>
            <span className="flex items-center gap-2">
              <span className="material-icons">collections</span>
              <span className="text-white">{photos}</span>
            </span>
            <span className="flex items-center gap-2">
              <span className="material-icons">videocam</span>
              <span className="text-white">{videos}</span>
            </span>
          </div>

            <div className={`overflow-hidden transition-[max-height] duration-300 ease-in-out
              ${isExpanded ? 'max-h-[1000px]' : 'max-h-[4.5rem]'}`}>
              <p className="text-gray-300">
                {description}
              </p>
            </div>
          </div>
        </div>

        <div className="mt-auto">
          <div className="border border-gray-600 mb-4"></div>
          
          <div className="grid grid-cols-3 gap-3 mt-2">
            <span 
              onClick={handleExpandClick}
              className="bg-transparent text-[#00aff0] px-3 py-2
                rounded-lg text-sm font-medium border border-[#00aff0]/20
                flex items-center gap-2 flex-1 justify-center
                hover:bg-[#00aff0]/10 transition-colors duration-200"
            >
              {isExpanded ? 'Show less' : 'See description'}
            </span>
            <a href={url} target="_blank" className="bg-[#00aff0] text-white px-3 py-2 col-span-2
                rounded-lg text-sm font-medium border border-[#00aff0]
                flex items-center gap-2 flex-1 justify-center
                hover:bg-[#00aff0]/80 transition-colors duration-200">
              {source === 'OnlyFans' ? `${pricetext} on` : source === 'Fansly' ? `$${price} per month on` : ''}
              <span className="bg-white rounded-full p-1">
                <img src={logo} alt={source} className="w-6 h-6 " />
              </span>
              {source}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileCard; 