import React from "react";

const RawRemoteScript = () => {
    return (
        <>
            <script type="application/javascript">
                var ad_idzone = "5495068",
                ad_popup_fallback = false,
                ad_popup_force = false,
                ad_chrome_enabled = true,
                ad_new_tab = false,
                ad_frequency_period = 720,
                ad_frequency_count = 1,
                ad_trigger_method = 3,
                ad_trigger_delay = 0,
                ad_capping_enabled = true; 
            </script>
            <script type="application/javascript" src="https://a.pemsrv.com/popunder1000.js"></script>
        </>
    );
  };

export default RawRemoteScript;

