function AgeVerification({ onVerify }) {
  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-md z-50 flex items-center justify-center">
      <div className="bg-[#333645] p-8 rounded-2xl max-w-md w-full mx-4 shadow-xl border-2 border-zinc-700">
        <h2 className="text-2xl font-bold text-white mb-4">Age Verification Required</h2>
        <p className="text-gray-300 mb-6">
          This website contains adult material. You must be at least 18 years old to enter.
        </p>
        <div className="flex gap-4">
          <button
            onClick={() => onVerify(true)}
            className="flex-1 bg-[#00aff0] text-white px-4 py-2 rounded-lg 
              hover:bg-[#00aff0]/80 transition-colors duration-200"
          >
            I am 18 or older
          </button>
          <button
            onClick={() => onVerify(false)}
            className="flex-1 bg-gray-600 text-white px-4 py-2 rounded-lg 
              hover:bg-gray-700 transition-colors duration-200"
          >
            I am under 18
          </button>
        </div>
      </div>
    </div>
  );
}

export default AgeVerification;
