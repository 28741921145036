import { useEffect } from "react";

const StickyBannerComponent = (className, data_zoneid) => {
  useEffect(() => {
    // Load the external script dynamically
    const script = document.createElement("script");
    script.async = true;
    script.type = "application/javascript";
    script.src = "https://a.magsrv.com/ad-provider.js";
    document.body.appendChild(script);

    // Push the ad provider serve function
    script.onload = () => {
      if (window.AdProvider) {
        window.AdProvider.push({ serve: {} });
      }
    };

    return () => {
      // Clean up script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="w-[200px]">
      {/* Ad container */}
      <ins className="eas6a97888e17" data-zoneid="5495088"></ins>
    </div>
  );
};

export default StickyBannerComponent;
