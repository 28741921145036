import { useState } from 'react';
import SearchBar from './SearchBar';
import ProfileList from './ProfileList';
import { searchProfiles } from '../services/api';

function SearchPage() {
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = async (query) => {
    setLoading(true);
    if (query.trim() === '') {
      query = '*'
    }
    try {
      const results = await searchProfiles(query);
      setProfiles(results);
    } catch (error) {
      console.error('Search failed:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <SearchBar onSearch={handleSearch} />
      {loading ? (
        <p className="text-center mt-8">Loading...</p>
      ) : (
        <ProfileList profiles={profiles} />
      )}
    </div>
  );
}

export default SearchPage; 