import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const searchProfiles = async (query) => {
  // When backend is ready, uncomment this:
  const response = await axios.post(`${API_URL}/goonly/api/search`, {
    search: query
  });
  return response.data;
};