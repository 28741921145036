import { useState } from 'react';
import ProfileCard from './ProfileCard';

function ProfileList({ profiles }) {
  const [expandedRow, setExpandedRow] = useState(null);

  const handleExpand = (index) => {
    // Calculate which row this card is in based on screen width
    const cardsPerRow = window.innerWidth >= 1536 ? 3 : window.innerWidth >= 1024 ? 2 : 1;
    const row = Math.floor(index / cardsPerRow);
    setExpandedRow(expandedRow === row ? null : row);
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-8 mt-12">
      {profiles.map((profile, index) => {
        const cardsPerRow = window.innerWidth >= 1536 ? 3 : window.innerWidth >= 1024 ? 2 : 1;
        const row = Math.floor(index / cardsPerRow);
        const isExpanded = row === expandedRow;

        return (
          <ProfileCard
            key={index}
            profile={profile}
            isExpanded={isExpanded}
            onExpand={() => handleExpand(index)}
          />
        );
      })}
    </div>
  );
}

export default ProfileList; 